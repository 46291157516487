<template>
  <div class="card transferencia">
    <div class="transferencia__contenedor">
      <p><strong>Gestion Beneficio SUBE</strong>
        <small><strong>*El descuento que se aplica en la tarifa es del 55%</strong></small>
      </p>
      <p>Estado : {{
        estado == 'alta_pendiente' ? 'Alta Pendiente' :
          estado == 'activo' ? "Activo" :
            estado == 'baja_pendiente' ? 'Baja Pendiente' :
              "Inactivo"
      }}</p>
      <button class="btn btn-danger btn_gestion_sube" v-if="estado == 'activo'" @click="deshabilitarBeneficioSube({'tipo_documento': persona['tipo_documento'], 'numero_documento' : persona['numero_documento']})">Dar De Baja</button>
      <button class="btn btn-primary btn_gestion_sube" v-if="estado == 'inactivo' || estado == 'sin_beneficio'"
        @click="habilitarBeneficioSube({'tipo_documento': persona['tipo_documento'], 'numero_documento' : persona['numero_documento']})">Activar</button>
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import Inactivo from "../../../beneficioSube/estados/Inactivo.vue";
import AltaPendiente from "../../../beneficioSube/estados/AltaPendiente.vue";
import BajaPendiente from "../../../beneficioSube/estados/BajaPendiente.vue";
import Activo from "../../../beneficioSube/estados/Activo.vue";

export default {
  name: "Sube",
  components: {
    subeInactivo: Inactivo,
    subeAltaPendiente: AltaPendiente,
    subeBajaPendiente: BajaPendiente,
    subeActivo: Activo

  },


  computed: {
    ...mapState({
      estado: state => state.gestionbeneficiosube.estado,
      searchError: state => state.gestionbeneficiosube.errors.length > 0,
      errorsArray: state => state.gestionbeneficiosube.errors,
      persona: state => state.gestionbeneficiarios.persona
    }),
  },

  methods: {
    ...mapActions('gestionbeneficiosube', [
      'obtenerEstadosBeneficiario',
      'habilitarBeneficioSube',
      'deshabilitarBeneficioSube',
    ]),
  },

  mounted() {
    this.obtenerEstadosBeneficiario({ "tipo_documento": this.persona['tipo_documento'], "numero_documento": this.persona["numero_documento"] });
  },

}
</script>
<style lang="scss" scoped>
.btn_gestion_sube{
  float:right
}
</style>