const enviarFormularioContacto = (datos) => {
    return axios.post("ayuda/contacto", datos)
}

const obtenerTemasDeAyuda = () => {
    return axios.get("ayuda/contacto/temas")
}


export {
    enviarFormularioContacto,
    obtenerTemasDeAyuda
}
