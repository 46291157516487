const getRecibosExternosPorCUIT = (cuit, opcion) => {
    return axios.get(`gestion-externos/buscar-recibos/${opcion}/buscar-cuit/${cuit}`)
}

const getRecibosExternosPorBeneficio =(beneficio,opcion) => {
    return axios.get(`gestion-externos/buscar-recibos/${opcion}/buscar-beneficio/${beneficio}`)
}

export {
    getRecibosExternosPorCUIT,
    getRecibosExternosPorBeneficio
}
