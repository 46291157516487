<template>
    <div class="container formulario_contacto_container">
        <h1>Contáctese con nosotros</h1>
        <div class="w-70 card p-3">
            <div>
                <label for="nombre" class="form-label">Nombre y apellido*</label>
                <input type="text" v-model="nombre" class="form-control">
            </div>
            <div>
                <label for="email" class="form-label">Correo electrónico *</label>
                <input type="email" v-model="email" class="form-control">
            </div>
            <div>
                <label for="telefono" class="form-label">Teléfono *</label>
                <input type="telefono" v-model="telefono" class="form-control">
            </div>
            <div>
                <label for="exampleInputEmail1" class="form-label">Tema por el que quiere consultar *</label>
                <select class="form-control" aria-label="Default select example" v-model="selected">
                    <option v-for="(topic, index) in help_topics" :value="topic" :key="index">{{ index + 1 }} -
                        {{ topic.description }}</option>
                </select>
            </div>
            <div>
                <label for="mensaje" class="form-label">Mensaje *</label>
                <input type="text" v-model="mensaje" class="form-control">
            </div>
            <div v-if="error"><small>*{{mensaje_error  }}</small></div>
            <button class="mt-5 btn btn-primary" v-on:click="sendEmail">Enviar</button>
        </div>
    </div>
</template>
<script>
import { esMailValido, esTelefono } from '../../helpers';
import { enviarFormularioContacto, obtenerTemasDeAyuda } from '../../api/formulariocontacto';

export default {
    data() {
        return {
            nombre: "",
            email: "",
            telefono: "",
            mensaje: "",
            error: false,
            mensaje_error: "",
            help_topics: [],
            selected: null
        }
    },

    created() {
        this.getHelpTopics()
    },

    methods: {
        async getHelpTopics() {
            obtenerTemasDeAyuda()
                .then(response => {
                    this.help_topics = response.data
                })
                .catch(error => {
                    console.log(error.message)
                })
        },
        sendEmail() {

            if (this.nombre == "" || this.email == "" || this.telefono == "" || this.mensaje == "" || this.selected == null) {
                this.error = true
                this.mensaje_error = "Todos los campos son obligatorios"
            }
            else if (!esMailValido(this.email)) {
                this.error = true
                this.mensaje_error = "El email debe ser una direccion válida"
                console.log(this.mensaje_error)
            }
            else if (!esTelefono(this.telefono)) {
                this.error = true
                this.mensaje_error = "Debe Ser un telefono válido"
                console.log(this.mensaje_error)
            } else {
                this.error =false
                let formData = new FormData
                formData.append('nombre', this.nombre)
                formData.append('email_remitente', this.email)
                formData.append('telefono', this.telefono)
                formData.append('mensaje', this.mensaje)
                for (const [key, value] of Object.entries(this.selected)) {
                    formData.append(`${key}`, `${value}`)
                }
                enviarFormularioContacto(formData).then(response => {
                    this.$swal.fire({
                        title: "Consulta Enviada",
                        text: "Gracias por contactarnos.Les responderemos a la brevedad",
                        timer:2000,
                        icon: "success"
                    })
                }).catch(error => {
                    console.log(error.response.data)
                    this.$swal.fire({
                        title: "Algo salió mal",
                        text: error.response.data,
                        timer:2000,
                        icon: "error"
                    })
                })
            }
        }
    }
}


</script>
<style lang="scss">
.formulario_contacto_container{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
</style>
