<template>
    <div>

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">Está en: Mi Caja</li>
            </ol>
        </nav>
        <button @click="generarQR">CrearQR</button>

        <novedad-noticias-beneficiarios v-if="rolBeneficiario">

        </novedad-noticias-beneficiarios>

        <novedad-ultimo-recibo v-if="rolBeneficiario"></novedad-ultimo-recibo>

        <novedad-punto-a-punto v-if="rolUsuario"></novedad-punto-a-punto>

        <novedad-empleados-agosto v-if="rolEmpleado"></novedad-empleados-agosto>

        <novedad-mis-recibos v-if="rolEmpleado"></novedad-mis-recibos>

        <novedad-aplicacion-usuarios v-if="appOrganismoConsulta"></novedad-aplicacion-usuarios>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import NovedadMisRecibos from "./novedades/NovedadMisRecibos.vue";
import NovedadPuntoAPunto from "./novedades/NovedadPuntoAPunto.vue";
import NovedadProgramaTransaccional from "./novedades/NovedadProgramaTransaccional.vue";
import NovedadUltimoRecibo from "./novedades/NovedadUltimoRecibo.vue";
import NovedadAplicacionUsuarios from "./novedades/NovedadAplicacionUsuarios.vue";
import NovedadEmpleadosAgosto from "./novedades/NovedadEmpleadosAgosto.vue";
import NovedadNoticiasBeneficiarios from "./novedades/NovedadesBeneficiariosMiCaja.vue"

export default {
    components: {
        "novedad-mis-recibos": NovedadMisRecibos,
        "novedad-punto-a-punto": NovedadPuntoAPunto,
        "novedad-programa-transaccional": NovedadProgramaTransaccional,
        "novedad-ultimo-recibo": NovedadUltimoRecibo,
        "novedad-aplicacion-usuarios": NovedadAplicacionUsuarios,
        "novedad-empleados-agosto": NovedadEmpleadosAgosto,
        "novedad-noticias-beneficiarios": NovedadNoticiasBeneficiarios
    },
    methods: {
        generarQR() {
            axios.get("credencial/obtenerqr/060161890", { 'Content-type': 'image/png' }).then(response => {

                var a = document.createElement("a"); //Create <a>
                a.href = "data:image/png;base64," + response.data.data; //Image Base64 Goes here
                a.download = "Image.png"; //File name Here
                a.click();
            })
        }
    },
    computed: mapState({
        rolBeneficiario: state => {
            return state.user.roles.includes('BENEFICIARIO')
        },
        rolUsuario: state => {
            return state.user.roles.includes('USUARIO')
        },
        rolEmpleado: state => {
            return state.user.roles.includes('EMPLEADO')
        },
        appOrganismoConsulta: state => {
            return state.user.aplicaciones.some(a => a.aplicacion === 'USUARIOS' && a.funcion === 'USUARIOS-ORGANISMOS-CONSULTA') && state.user.roles.includes('EMPLEADO')
        }
    }),
};
</script>
