<template>
    <main class="w-100">
        <div class="credencial_container">
            <h1 class="w-100 text-center text-success">Credencial Válida</h1>
            <div class="card credencial_card">
                <div class="card-title titulo_card">
                    <img alt="Escudo Caja de Retiros, Jubilaciones y Pensiones de la Policía Federal"
                        src="http://localhost/imagenes/logo-caja.png" height="40" class="logo-caja">
                    <p class=" card-title titulo_credencial text-center">Caja de Retiros, Jubilaciones y Pensiones de la
                        Policía Federal</p>
                </div>

                <div class="credencial_spacer w-80 text-center"></div>
                <div class="card-body">
                    <div class="credencial_primera_linea">
                        <div>
                            <div class="credencial_label apellido_label">Apellido</div>
                            <div class="credencial_texto_campo">{{ datos['apellido'] }}</div>
                        </div>
                        <div>
                            <div class="credencial_label tipo_beneficio_label">Tipo Beneficio</div>
                            <div class="credencial_texto_campo">{{ datos['tipo_beneficio'] }}</div>
                        </div>
                        <div>
                            <div class="credencial_label num_beneficio_label">Nro.</div>
                            <div class="credencial_texto_campo">{{ datos['numero_beneficio'] }}</div>
                        </div>
                    </div>
                    <div class="credencial_segunda_linea ">
                        <div>
                            <div class="credencial_label">Nombre</div>
                            <div class="credencial_texto_campo">{{ datos['nombre'] }}</div>
                        </div>
                    </div>
                    <div class="credencial_tercera_linea">
                        <div>
                            <div class="credencial_label">Tipo y Nro. Documento</div>
                            <div class="credencial_texto_campo">{{ datos['documento'] }}</div>
                        </div>
                    </div>
                </div>
                <div class="card-footer credencial_card_footer">
                    <div class="qrcode">
                        <img v-bind:src="'data:image/png;base64,' + datos['qr_base_64']"
                            style="width: 50px; height: 50px; border:1px solid #35b8ec" />
                        <span>Jerarquia - {{ datos['jerarquia'] }}</span>
                    </div>
                    <div>
                        <img src="http://localhost/imagenes/minseg.png" style="height: 80px;" />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    props: ['datos'],
}
</script>
<style scoped>
.credencial_container {
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.credencial_card {
    width: 500px;
    font-weight: 600;
}

.credencial_spacer {
    border-bottom: 2px solid #35b8ec;
    margin-bottom: 5px;
}

.titulo_credencial {
    align-self: center;
    margin-top: 2px;
    font-size: medium;
}

.credencial_primera_linea {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

}

.credencial_segunda_linea,
.credencial_tercera_linea {
    margin-top: 3px;
}

.credencial_texto_campo {
    font-size: 1.3rem;
}

.credencial_card_footer {
    background-color: #35b8ec;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}

.titulo_card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.tipo_beneficio_label {}

.num_beneficio_label {}
</style>
