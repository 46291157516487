<template>
    <div class="container">
        <div v-if="!isSearching">
            <credencial-valida v-if="isValid" :datos="datos"></credencial-valida>
            <credencial-invalida v-else></credencial-invalida>
        </div>
        <div class="mr-2 spinner-border text-info" role="status" v-else>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import CredencialValida from './CredencialValida.vue';
import CredencialInvalida from './CredencialInvalida.vue';

export default {
    components: {
        credencialValida: CredencialValida,
        credencialInvalida: CredencialInvalida
    },
    methods: {
        validarCredencial(validador, signature) {

            let validationString = `${validador}?signature=${signature}`

            axios.get(`credencial/verificar/${validationString}`)
                .then(response => {
                    this.datos = { ...response.data.datos }
                    this.isValid = true;
                    this.isSearching = false;
                })
                .catch(error => {
                    this.isValid = false;
                    this.isSearching = false;
                })

        }
    },
    async created() {
        let validador = this.$route.params.validador || undefined;
        let signature = this.$route.query.signature || undefined;
        if (validador != undefined && signature != undefined) {
            this.validarCredencial(validador, signature)
        } else {
            this.isSearching = false
        }
    },
    data() {
        return {
            datos: {},
            isValid: false,
            isSearching: true

        }
    },
    watch: {
        // whenever question changes, this function will run
        isValid: function (newValue, oldValue) {
            this.isValid = newValue

        },
        isSearching: function (newValue, oldValue) {
            this.isSearching = newValue

        }
    },
}

</script>
