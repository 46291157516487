<template>
    <div class="container d-flex flex-row flex-wrap"></div>
</template>

<script>

export default {

    name: "VerificacionCertificados"

};

</script>
