<template>
    <div class="card certificado-haberes shadow-sm rounded-lg border-0 mb-3 p-4">
        <div class="d-flex flex-column flex-md-row justify-content-start justify-content-md-between">
            <div class="certificado-haberes__badges mb-3 order-1 order-md-2">
                <badge-tramite :tramite="certificado.tramite.codigo.codigo"></badge-tramite>
                <badge-estado :estado="ultimaHistoria.estado.estado"></badge-estado>
            </div>
            <div class="certificado-haberes__titulo order-2 order-md-1">
                <p>{{ certificado.tramite.codigo.codigo }}-{{ certificado.numero }}</p>
                <h4>{{ certificado.beneficio.personas[0].nombre }} {{ certificado.beneficio.personas[0].apellido }}</h4>
                <p>{{ certificado.beneficio.personas[0].tipo_documento }} {{
                    certificado.beneficio.personas[0].numero_documento }} | Beneficio: {{
                        certificado.beneficio.beneficio_id | formatearBeneficio }}<br>
                    Destino: <span v-if="certificado.organismo_id">{{ certificado.organismo.nombre_organismo
                        }}</span><span v-else>{{ certificado.destino }}</span><br>
                    <span class="text-muted">Generado el {{ moment(certificado.created_at).format('LLL') }} - Válido
                        hasta el {{ moment(certificado.created_at).add(20, 'days').format('LL') }}</span>
                </p>

            </div>
        </div>

        <div class="certificado-haberes__acciones">
            <div class="accion-recibo d-flex flex-column flex-md-row justify-content-end">

                <button type="button" class="btn btn-primary mr-0 ml-md-2 btn-sm"
                    v-on:click="descargarCertificado(certificado.numero)">
                    <i class="fas fa-arrow-alt-circle-down fa-2x"></i>
                    <span class="px-2">Descargar</span>
                </button>

            </div>
        </div>
    </div>
</template>

<script>

import BadgeEstado from '../../../../elementos/badges/BadgeEstado.vue'
import BadgeTramite from '../../../../elementos/badges/BadgeTramite.vue'

export default {
    data() {
        return {
            certificadoHaber: this.certificado,
            certificadoAAnular: null
        }
    },
    components: {
        'badge-estado': BadgeEstado,
        'badge-tramite': BadgeTramite
    },
    props: [
        'certificado',
        'beneficio'
    ],
    computed: {
        ultimaHistoria() {
            return this.certificadoHaber.ultima_historia
        }
    },
    methods: {
        descargarCertificado: function (numero_certificado) {
            this.descargandoCertificado = 1
            axios.get(this.beneficio + '/mis-documentos/mis-certificados-haberes/' + numero_certificado + '/descargar')
                .then((response) => {
                    var filename = 'CERTHAB-' + numero_certificado + '-' + this.certificado.beneficio_id + '.pdf'
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$swal.fire({
                        title: error.response.data.error

                    }
                    )
                })
        }
    }
};

</script>
