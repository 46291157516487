<template>
    <div class="container d-flex flex-row flex-wrap">
        <div class="w-100 formulario formulario-ingresar">

            <!-- <div class="w-100 alertas" v-if="alertaSuccess">
				<div class="alert alert-success alert-dismissible fade show" role="alert" v-if="alertaSuccess">
					Certificado generado correctamente. <button class="btn btn-link alert-link pt-0 px-0" v-on:click.stop.prevent="descargarCertificado(numero)">Descargar certificado</button>
					<button type="button" class="close" data-dismiss="alert" aria-label="Close">
					    <span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
 -->
            <div class="w-100 alertas" v-if="alertaError || alertaAdvertencia">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="alertaAdvertencia">
                    {{ alertaAdvertencia }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>


                <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertaError">
                    {{ alertaError }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>



            <div class="row">
                <div class="col-12">
                    <h4>Verificación de documentos</h4>
                </div>
            </div>


            <form id="formularioVerificarCertificadoHaberes" novalidate @submit.prevent>

                <div class="row">
                    <div class="col-12 form-group">
                        <label for="codigo_certificado">Ingresá los 12 caracteres alfanuméricos que figuran en el
                            certificado de haberes (no distingue mayúsculas de minúsculas)</label>
                        <input ref="codigo_certificado" class="form-control input-buscar-beneficio" type="text"
                            name="codigo_certificado" v-model="codigo_certificado" id="codigo_certificado"
                            onclick="this.select();" placeholder="Ingresa los 12 caracteres alfanuméricos"
                            maxlength="12">
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-primary" v-on:click="buscarCertificado(codigo_certificado)">
                            <span v-if="buscandoCertificado == 'buscar' || buscandoCertificado == 'encontrado'">Buscar
                                certificado</span>
                            <span v-else-if="buscandoCertificado == 'buscando'">Buscando información <i
                                    class="fas fa-circle-notch faa-spin animated faa-fast"></i></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div v-if="datosCertificado" class="col-md-8 offset-md-2">
            <div v-if="datosCertificado.baja_certificado">
                <i class="fas fa-times-circle fa-3x" style="color: #c62828;"></i>
                <h4>El certificado de haberes {{ datosCertificado.tramite.codigo.codigo }}-{{ datosCertificado.numero }}
                    fue
                    anulado</h4>
            </div>
            <div v-else-if="moment().isAfter(moment(datosCertificado.certificado_datos.vencimiento), 'day')">
                <i class="fas fa-times-circle fa-3x" style="color: #c62828;"></i>
                <h4>El certificado de haberes {{ datosCertificado.tramite.codigo.codigo }}-{{ datosCertificado.numero }}
                    se
                    encuentra vencido</h4>
            </div>
            <div v-else>
                <i class="fas fa-check-circle fa-3x" style="color: #6ea100;"></i>
                <h4>Datos del certificado de haberes
                    {{ datosCertificado.tramite.codigo.codigo }}-{{ datosCertificado.numero }}</h4>
            </div>
            <p> Generado el {{ moment(datosCertificado.certificado_datos.created_at).format('LL') }} | Válido hasta el
                {{ moment(datosCertificado.certificado_datos.vencimiento).format('LL') }}</p>
            <p> Beneficiario: {{ datosCertificado.beneficio.personas[0].apellido }},
                {{ datosCertificado.beneficio.personas[0].nombre }}<br>
                Documento: {{ datosCertificado.beneficio.personas[0].tipo_documento }}
                {{ datosCertificado.beneficio.personas[0].numero_documento }} | Beneficio:
                {{ datosCertificado.beneficio.beneficio_id | formatearBeneficio }}</p>
            <p><strong>Retribuciones mensuales correspondientes al mes de
                    {{ datosCertificado.certificado_datos.nombre_proceso | nombreReciboPB }}</strong></p>
            <p> Haber bruto: {{ datosCertificado.certificado_datos.haber_bruto | currency }} | Haber neto: {{
                datosCertificado.certificado_datos.haber_neto | currency }}<br>
                <span v-if="datosCertificado.organismo_id"> Porcentaje de afectación: {{
                    datosCertificado.certificado_datos.porcentaje_afectacion | currency }} | Afectación disponible: {{
                        datosCertificado.certificado_datos.afectacion_disponible | currency }}</span>
            </p>
            <p>Destino: <span v-if="datosCertificado.organismo_id">{{ datosCertificado.organismo.nombre_organismo
                    }}</span><span v-else>{{ datosCertificado.destino }}</span></p>





        </div>
    </div>
</template>

<script>
export default {

    methods: {
        buscarCertificado: function (codigo_certificado) {


            var certificadoABuscar = codigo_certificado

            if (certificadoABuscar.length != 12) {
                this.alertaAdvertencia = 'El código a ingresar es de 12 caracteres.'
                return
            }
            /* Limpiar el campo beneficio */
            // var beneficioABuscar = beneficio.replace(/ /g, '')
            // beneficioABuscar = beneficioABuscar.replace(/-/g, '')
            // beneficioABuscar = beneficioABuscar.replace(/\//g, '')
            // beneficioABuscar = beneficioABuscar.padEnd(9, '0')

            /* Verificar que el beneficio a consultar sea diferente al ya consultado */
            if (this.certificadoBuscado == certificadoABuscar) {
                this.alertaAdvertencia = 'Ya estás consultado este certificado de haberes'
                this.buscandoCertificado = 'buscar'
                return
            }

            /* Inicializar variables */
            this.datosCertificado = null
            this.alertaError = null
            this.alertaAdvertencia = null
            this.alertaSuccess = null
            this.buscandoCertificado = 'buscando'

            /* Validaciones */
            if (codigo_certificado == '') {
                this.alertaAdvertencia = 'Tenés que ingresar el código del certificado para realizar la búsqueda.'
                this.buscandoCertificado = 'buscar'
                return
            }

            /* Llamada a la API */
            axios.get('verificacion/certificados-haberes/' + codigo_certificado)
                .then((response) => {


                    if (response.data.length == 0) {
                        this.alertaAdvertencia = 'No se encontró información con ese código. Por favor volvé a intentarlo.'
                        return
                    }

                    this.buscandoCertificado = 'encontrado'
                    this.codigo_certificado = certificadoABuscar
                    this.datosCertificado = response.data
                    this.certificadoBuscado = certificadoABuscar

                })
                .catch((error) => {
                    this.buscandoCertificado = 'buscar'
                    this.certificadoBuscado = certificadoABuscar
                    this.datosCertificado = null

                    switch (error.response.status) {
                        case 500:
                            this.alertaError = 'Hubo un inconveniente con la búsqueda del certificado de haberes. Por favor volvé a intentarlo en unos minutos.'
                            break;

                        case 404:
                            this.alertaError = 'No se encontró información con el código ingresado.'
                            break;

                        default:
                            this.alertaError = error.response.data.error
                    }
                })
        }
    },
    data() {
        return {
            environment: process.env.MIX_ENVIRONMENT,

            // Campos del formulario
            codigo_certificado: null,

            // Acción enviar
            buscandoCertificado: 'buscar',
            certificadoBuscado: null,

            // Alertas
            alertaError: null,
            alertaAdvertencia: null,
            alertaSuccess: null,

            // Datos para el certificado
            datosCertificado: null

        }
    },


};

</script>
