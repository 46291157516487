<template>
    <div  v-if="loading_opciones_busqueda" class="spinner_container">
        <div class="spinner-grow text-primary spinner_opciones_busqueda"   role="status">
            <span class="sr-only ">Loading...</span>
        </div>
    </div>
    <div v-else>
        <buscador-recibos-por-rango></buscador-recibos-por-rango>
        <br/>
        <div class="alert alert-primary w-100 d-inline-flex align-items-center justify-content-center" v-if="loading">
            <div class="mr-2 spinner-border text-info" role="status">
            </div>
            <div class="ml-2" >Buscando Los Datos ...</div>
        </div>
        <div v-else>
            <div class="alert alert-primary" v-if="recibos.length == 0 && !recibosBuscados">
            Puede realizar la búsqueda de Recibos por número de documento o número de CUIL
            </div>
            <div v-if="errores.length>0">
                <div v-for="error in errores" class="alert alert-danger" >
                    {{error}}
                </div>
            </div>
            <lista-administrador-recibos v-else :recibosEncontrados="recibos" :persona="persona"></lista-administrador-recibos>
        </div>
    </div>
</template>
<script>
import BuscadorRecibosPorRango from './buscador/BuscadorRecibosPorRango.vue';
import ListaAdministradorRecibos from "./listaRecibos/ListaAdministradorRecibos.vue";
import {mapState, mapActions} from 'vuex';

export default {
    name: "MisaplicacionesAdministracionRecibos",

    components:{
        BuscadorRecibosPorRango,
        ListaAdministradorRecibos,

    },
    created() {
            this.traerOpcionesDeBusqueda();
            this.getMinDate();
        },
    methods: {
            ...mapActions('administradorreciboshaberes', [
                "traerOpcionesDeBusqueda",
                "getMinDate"
            ]),
        },
    computed: {
        ...mapState({
            loading_opciones_busqueda: state => {
                return state.administradorreciboshaberes.loading_opciones_busqueda
            },
            recibos: state => {
                return state.administradorreciboshaberes.recibos
            },
            persona: state=>{
                return state.administradorreciboshaberes.persona
            },
            errores: state=>{
                return state.administradorreciboshaberes.errors
            },
            loading: state=>{
                return state.administradorreciboshaberes.loading
            },
            recibosBuscados: state => {
                return  state.administradorreciboshaberes.recibosBuscados
            }
        })
    },

}
</script>
<style lang="scss">
.spinner_container{
    display:flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.spinner_opciones_busqueda{
    width: 10rem;
    height: 10rem
}
</style>
