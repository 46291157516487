import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import persona from './modules/persona';
import beneficios from './modules/beneficios';
import {
	administradorreciboshaberes,
    gestionbeneficiarios,
	gestionbeneficiosube,
	gestionexternos,
    notificaciones,
	novedades,
} from "./modules/aplicaciones/index"

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		administradorreciboshaberes,
		beneficios,
		gestionbeneficiarios,
		gestionbeneficiosube,
        gestionexternos,
       	notificaciones,
		novedades,
		persona,
		user
	}
})
