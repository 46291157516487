<template>
    <div class="w-100">
        <div class="col-12 col-md-9">
            <h3>Nuevo Certificado de Haberes</h3>
        </div>

        <div class="p-3 p-md-4 formulario formulario-ingresar">
            <div class="w-100 mb-2" v-if="beneficiosBajadaBenef.length > 0">
                <h4>Información correspondiente al {{ beneficiosBajadaBenef[0].nombre_proceso | nombreReciboPB }}</h4>
                <p v-for="beneficio in beneficiosBajadaBenef">
                    Beneficio: <span>{{ beneficio.beneficio_id | formatearBeneficio }}</span><br>
                    Haber bruto: <span>{{ beneficio.haber_bruto | currency }}</span><br>
                    Haber neto: <span>{{ beneficio.haber_neto | currency }}</span><br>
                    Porcentaje de afectación: <span>{{ beneficio.porcentaje_afectacion | currency }}</span><br>
                    Afectación disponible: <span>{{ beneficio.afectacion_disponible | currency }}</span>
                </p>
            </div>

            <hr>

            <div class="w-100 alertas" v-if="alertaError || alertaAdvertencia || alertaCertificadoCovenido">
                <div class="alert alert-warning alert-dismissible fade show" role="alert" v-if="alertaAdvertencia">
                    {{ alertaAdvertencia }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="alert alert-warning" v-if="alertaCertificadoCovenido && descuento_recibo === 'si'">
                    <p>
                        Ya tiene emitido un certificado de haberes para realizar
                        descuento por recibo de haberes. Recuerde que cuando se
                        aplique el descuento en su recibo de haberes, podrá emitir
                        un nuevo certificado.
                    </p>
                    <router-link :to="{ name: 'CertificadosHaberesFAQ' }">
                        <a href="#" class="alert-link">¿Quiere solicitar la anulación?</a>
                    </router-link>
                </div>

                <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertaError">
                    {{ alertaError }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

            <form id="formularioNuevoCertificadoBeneficiario" novalidate @submit.prevent>
                <div class="row" v-if="beneficios.length > 1">
                    <div class="select-container col-12 form-group">
                        <label for="beneficio_id" class="font-weight-bold">
                            ¿Sobre cuál de los beneficios quiere obtener el certificado?
                        </label>
                        <select class="form-control" name="beneficio_id" v-model="beneficio_id" id="beneficio_id">
                            <option value="" disabled>
                                Seleccione un beneficio (obligatorio)
                            </option>
                            <option v-for="beneficio in beneficios" :value="beneficio">
                                {{ beneficio | formatearBeneficio }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="select-container col-12 form-group">
                        <label for="descuento_recibo" class="font-weight-bold">
                            ¿Es para descontar por recibo de haberes?
                        </label>
                        <p>
                            <small class="text-muted">Si elige la opción "Sí", entonces el
                                certificado de haberes puede ser presentado
                                únicamente ante entidades que tienen convenio
                                con la Caja. <a href="#" data-toggle="modal"
                                    data-target="#nuevoCertHaberInfoImportante">Importante</a>
                            </small>
                        </p>
                        <select class="form-control" name="descuento_recibo" v-model="descuento_recibo"
                            id="descuento_recibo">
                            <option value="" disabled>
                                Seleccione una opción (obligatorio)
                            </option>
                            <option value="si">
                                Sí, es para descontar por recibo de haberes
                            </option>
                            <option value="no">
                                No, no es para descontar por recibo de haberes
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row" v-if="descuento_recibo === 'si'">
                    <div class="select-container col-12 form-group">
                        <label for="organismo_concepto" class="font-weight-bold">
                            ¿Ante qué entidad quiere presentar el certificado?
                        </label>
                        <p>
                            <small class="text-muted">
                                Si quiere presentar el certificado en otro
                                lugar de los que figuran en la siguiente lista,
                                seleccione "No, no es para descontar por recibo
                                de haberes" en la opción anterior.
                            </small>
                        </p>
                        <select id="organismo_concepto" class="form-control" name="organismo_concepto"
                            v-model="organismo_concepto" required>
                            <option value="" disabled>
                                Seleccione una entidad (obligatorio)
                            </option>
                            <option v-for="organismo in organismosPorClase" :value="organismo.codigo">
                                {{ organismo.organismo.nombre_organismo }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row" v-if="descuento_recibo === 'no'">
                    <div class="col-12 form-group">
                        <label for="destino" class="font-weight-bold">
                            Escriba el nombre de la entidad ante la cual presentará el certificado de haberes
                        </label>
                        <input class="form-control" type="text" name="destino" v-model="destino" id="destino"
                            placeholder="Escriba el nombre de la entidad" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 form-group">
                        <div class="form-check">
                            <input type="checkbox" id="checkbox" v-model="checked" class="form-check-input">
                            <small class="form-check-label text-sm">
                                ¿Formula consentimiento para que la CRJPPF realice los descuentos por las prestaciones
                                y/o servicios a través de su haber previsional para la entidad seleccionada o
                                indicada?<br>
                                Recuerde que es preciso su consentimiento a fines de expedir el presente certificado.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <button :disabled="isDisabled" class="btn btn-primary" @click="generarCertificado">
                            <span v-if="generando === 'generar'">
                                Generar certificado
                            </span>
                            <span v-else-if="generando === 'generando'">
                                Generando certificado <i class="fas fa-circle-notch faa-spin animated faa-fast"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Modal Información Importante -->
        <div class="modal fade" id="nuevoCertHaberInfoImportante" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                            Información importante
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Tenga en cuenta lo siguiente:</p>
                        <p>
                            Si elige que el certificado sea para que la entidad
                            de destino le descuente del recibo de haberes, solo podra
                            solicitar un nuevo certificado por descuento cuando este
                            impacte en su recibo de haberes.
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    created() {
        this.obtenerInformacionBajadaBenef();
        this.obtenerConceptosOrganismos();
    },
    methods: {
        obtenerInformacionBajadaBenef() {
            axios.get("mis-tramites/mis-certificados-haberes/bajada-benef")
                .then(response => {
                    const { beneficios } = response.data
                    this.beneficiosBajadaBenef = beneficios.map(b => b.info_cert_haberes)
                    this.certificadosConvenidosAbiertos = (beneficios.filter(b => b.tiene_cert_convenido !== null)).map(c => c.tiene_cert_convenido)
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.alertaError = "Hubo un inconveniente al obtener informacion de sus haberes. Por favor vuelva a intentar en unos minutos.";
                    }
                });
        },
        obtenerConceptosOrganismos: function () {
            axios.get("obtener-organismos-conceptos-benef?por_pagina=200")
                .then(response => {
                    this.organismos = response.data.data.filter(organismo => organismo.codigo !== 658);
                })
                .catch(error => {
                    if (error.response.status >= 500) {
                        this.alertaError = "Hubo un inconveniente con la generación del certificado de haberes. Por favor vuelva a intentar en unos minutos.";
                    }
                });
        },
        generarCertificado: function () {
            // Inicializar valores
            this.alertaError = null;
            this.alertaAdvertencia = null;

            // Validaciones

            if (this.beneficio_id === "") {
                this.alertaAdvertencia = "Tiene que seleccionar un beneficio.";
                this.generando = "generar";
                return;
            }

            if (this.descuento_recibo === "") {
                this.alertaAdvertencia = "Tiene que elegir si el certificado se utilizará para realizar descuento por recibo de haberes.";
                this.generando = "generar";
                return;
            }

            if (this.descuento_recibo === "si" && this.organismo_concepto === "" && this.alertaCertificadoCovenido) {
                this.alertaAdvertencia = "Ya tiene emitido un certificado de haberes para realizar descuento por recibo de haberes. Recuerde que cuando se aplique el descuento en su recibo de haberes, podrá emitir un nuevo certificado.";
                this.generando = "generar";
                return;
            }

            if (this.descuento_recibo === "si" && this.organismo_concepto === "") {
                this.alertaAdvertencia = "Tiene que seleccionar una entidad para presentar el certificado.";
                this.generando = "generar";
                return;
            }

            if (this.descuento_recibo === "no" && (this.destino == null || this.destino === "")) {
                this.alertaAdvertencia = "Tiene que escribir el nombre de la entidad para presentar el certificado.";
                this.generando = "generar";
                return;
            }

            if (this.checked === false) {
                this.alertaAdvertencia = "Tiene que aceptar el consentimiento para que la CRJPPF realice los descuentos";
                this.generando = "generar";
                return;
            }

            var datoBeneficio = this.beneficiosBajadaBenef.filter(el => el.beneficio_id === this.beneficio_id);

            if (this.descuento_recibo === "no") {
                this.organismo_concepto = null;
                this.mensaje =
                    `<span class="font-weight-bold">Beneficio:</span> ${this.$options.filters.formatearBeneficio(this.beneficio_id)} <br>
                     <span class="font-weight-bold">Destino:</span> ${this.destino} <br>
                     <span class="font-weight-bold">Haber bruto:</span> ${this.$options.filters.currency(datoBeneficio[0].haber_bruto)} <br>
                     <span class="font-weight-bold">Haber neto:</span> ${this.$options.filters.currency(datoBeneficio[0].haber_neto)}`
            } else {
                this.mensaje =
                    `<span class="font-weight-bold">Beneficio:</span> ${this.$options.filters.formatearBeneficio(this.beneficio_id)} <br>
                     <span class="font-weight-bold">Destino:</span> ${this.organismosPorClase.filter(el => el.codigo === this.organismo_concepto)[0].organismo.nombre_organismo} <br>
                     <span class="font-weight-bold">Haber bruto:</span> ${this.$options.filters.currency(datoBeneficio[0].haber_bruto)} <br>
                     <span class="font-weight-bold">Haber neto:</span> ${this.$options.filters.currency(datoBeneficio[0].haber_neto)} <br>
                     <span class="font-weight-bold">Porcentaje de afectación:</span> ${this.$options.filters.currency(datoBeneficio[0].porcentaje_afectacion)} <br>
                     <span class="font-weight-bold">Total de afectación disponible:</span> ${this.$options.filters.currency(datoBeneficio[0].afectacion_disponible)}`
            }

            const data = {
                persona_id: this.persona_id,
                beneficio_id: this.beneficio_id,
                destino: this.destino,
                organismo_concepto: this.organismo_concepto,
                checked: this.checked
            };

            // Alerta confirmacion de cert haberes
            this.$swal.fire({
                title: "¿Desea confirmar la generación del certificado?",
                html: this.mensaje,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí",
                cancelButtonText: "No"
            })
                .then(result => {
                    if (result.isConfirmed) {
                        this.generando = "generando";
                        axios.post(this.beneficio_id + "/mis-tramites/mis-certificados-haberes", data)
                            .then(response => {
                                this.generando = "generado";
                                this.$router.push({
                                    name: "CertificadosHaberes", query: {
                                        certificado: "generado",
                                        codigo: response.data.numero,
                                        id: this.beneficio_id
                                    }
                                });
                            })
                            .catch(error => {
                                this.generando = "generar";
                                if (error.response.status >= 500) {
                                    this.alertaError = "Hubo un inconveniente con la generación del certificado de haberes. Por favor vuelva a intentar en unos minutos.";
                                } else {
                                    console.log(error.response.data.error);
                                    this.alertaError = error.response.data.error;
                                }
                            });
                    }
                });
        }
    },
    watch: {
        descuento_recibo: function () {
            if (this.descuento_recibo !== "") {
                this.alertaAdvertencia = null;
            }
        },
        beneficio_id: function () {
            if (this.beneficio_id !== "null") {
                this.alertaAdvertencia = null;
            }
        },
        organismo_concepto: function () {
            if (this.organismo_concepto !== "null") {
                this.alertaAdvertencia = null;
            }
        },
        destino: function () {
            if ((this.organismo_concepto === "null" && this.destino != null) || this.destino !== "") {
                this.alertaAdvertencia = null;
            }
        },
        beneficiosBajadaBenef: function () {
            if (this.beneficiosBajadaBenef.length === 1) {
                this.beneficio_id = this.beneficiosBajadaBenef[0].beneficio_id;
            }
        }
    },
    computed: {
        alertaCertificadoCovenido() {
            return this.certificadosConvenidosAbiertos.some(c => c.beneficio_id == this.beneficio_id)
        },
        clase() {
            return this.beneficio_id.substr(0, 2);
        },
        isDisabled() {
            return this.descuento_recibo === "si" && this.alertaCertificadoCovenido;
        },
        beneficios() {
            return this.beneficiosBajadaBenef.map(b => b.beneficio_id)
        },
        organismosPorClase() {
            if (this.clasesP18.indexOf(this.clase) >= 0) {
                return this.organismos.filter(el => el.programa_18 === "S");
            }
            if (this.clasesP19.indexOf(this.clase) >= 0) {
                return this.organismos.filter(el => el.programa_19 === "S");
            }
            if (this.clasesP20.indexOf(this.clase) >= 0) {
                return this.organismos.filter(el => el.programa_20 === "S");
            }
            if (this.clasesP21.indexOf(this.clase) >= 0) {
                return this.organismos.filter(el => el.programa_21 === "S");
            }
        },
    },
    data() {
        return {
            // Campos del formulario
            descuento_recibo: "",
            beneficio_id: "",
            organismo_concepto: "",
            destino: "",

            // Campos del store
            persona_id: this.$store.state.beneficios.beneficios[0].pivot.persona_id,
            //certHaberConvenido: this.$store.state.mistramites.certhaberes.certif_convenido_abierto,

            // Acción enviar
            generando: "generar",

            // Alertas
            alertaError: null,
            alertaAdvertencia: null,
            mensaje: "",
            checked: false,

            environment: process.env.MIX_ENVIRONMENT,
            masDeUnBeneficio: false,
            beneficiosBajadaBenef: [],
            certificadosConvenidosAbiertos: [],
            apoderados: null,
            organismos: null,
            clasesP18: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "31", "32", "33", "34", "35", "36", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "81", "82", "83", "84", "85", "86"],
            clasesP19: ["38", "88"],
            clasesP20: ["37", "87"],
            clasesP21: ["39", "89"],
        }
    }
};
</script>
