<template>
    <div class="container d-flex flex-row flex-wrap">
        <div class="w-100 formulario">
            <div v-if="es_valido" class="contenedor_validacion">
                <img class="contenedor_validacion_icon card-img-left" src="/imagenes/iconos/success_icon.png"
                    alt="..." />
                <div class=" contenedor_validacion_content">
                    <h3>El Recibo es Valido.</h3>
                    <div class="contenedor_validacion_datos">
                        <h4>Datos del recibo:</h4>
                        <small>-Beneficio : {{ datos_recibo["beneficio_id"] | formatearBeneficio }}</small>
                        <small>-Periodo : {{ datos_recibo["mes"] }}/{{ datos_recibo["anio"] }}</small>
                        <small>-Total Bruto : {{ datos_recibo["total_bruto"] | mapearMoneda }}</small>
                        <small>-Total Descuentos : {{ datos_recibo["total_descuentos"] | mapearMoneda }}</small>
                        <small>-Total Neto : {{ datos_recibo["total_neto"] | mapearMoneda }}</small>
                    </div>
                </div>
            </div>
            <div v-else class="contenedor_validacion">
                <img class="contenedor_validacion_icon card-img-left" src="/imagenes/iconos/fail_icon.png" alt="..." />
                <p class="contenedor_validacion_mensaje_error">{{ this.error_message }}</p>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    methods: {
        validarRecibo(numero) {
            this.buscando = true
            axios.get('/verificacion/recibo/' + numero)
                .then(response => {
                    this.datos_recibo = response.data
                    this.buscando = false
                    this.es_valido = true

                })
                .catch(error => {
                    this.error_message = error.response.data.error;
                    this.buscando = false
                    this.es_valido = false
                })
        }
    },
    data() {
        return {
            datos_recibo: null,
            es_valido: false,
            buscando: false,
            error_message: "",
        }
    },

    created() {
        let numero_recibo = this.$route.query.recibo
        this.validarRecibo(numero_recibo)

    },
};

</script>
<style lang="scss" scoped>
.contenedor_validacion {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.contenedor_validacion_icon {
    min-width: 20%;
}

.contenedor_validacion_content {
    padding: 1rem;
    min-width: 75%;
}

.contenedor_validacion_datos {
    display: flex;
    flex-direction: column;

}

.contenedor_validacion_datos h4 {
    margin: 0;

}

.contenedor_validacion_mensaje_error {
    min-width: 75%;
    padding: 1rem;
}
</style>
