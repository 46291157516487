<template>
    <div class="transferencia__contenedor">
        <span
            :class="[tipo === 'Titular' ? 'badge-verdin' : 'badge-celeste', 'badge transferencia__badge--estado mb-2']">{{
                tipo }}
            del Beneficio</span> <br />
        <strong>Beneficio: {{ data.clase_id | formatoNumClase }}-{{ data.beneficio | formatoNumBeneficio }}/{{
            data.barra
            | formatoNumBarra }}</strong> <br />
        Expediente: {{ data.expediente }} <br />
        Jerarquía: {{ data.jerarquia.nombre_jerarquia }} <br />
        Boca de Pago: {{ data.boca_pago.nombre_boca_pago }} <br />
        <span v-if="tipo === 'Titular'">Apoderado: <span v-if="data.apoderados.length > 0">Posee apoderado</span><span
                v-else>No posee apoderado</span></span>
        <span v-else>Vencimiento del poder: <span v-if="beneficio.vencimiento_poder">{{ beneficio.vencimiento_poder
                }}</span><span v-else>Sin vencimiento</span></span>
        <div class="container">

            <div class="row justify-content-start mt-3 mb-2">
                <button class="col-4 align-self-end btn btn-primary " role="tab"
                    @click="generarCertificadoNegativaSalarioFamiliar(data.beneficio_id)"><i
                        class="fas fa-download"></i> Generar Certificación
                    Negativa Salario Familiar</button>
            </div>

        </div>
        <div class="mt-2 p-2">
            <ul class="nav nav-pills nav-fill flex-column flex-sm-row mb-3" id="beneficios-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" id="beneficios-recibos-tab" data-toggle="pill"
                        :href="['#beneficios-recibos' + tipo + index]" role="tab" aria-controls="beneficios-recibos"
                        aria-selected="false">Recibos Haberes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="beneficios-certificados-tab" data-toggle="pill"
                        :href="['#beneficios-certificados' + tipo + index]" role="tab"
                        aria-controls="beneficios-certificados" aria-selected="false">Certificados Ganancias</a>
                </li>
            </ul>
            <div class="tab-content" id="beneficios-tabContent">
                <div class="tab-pane fade" :id="['beneficios-recibos' + tipo + index]" role="tabpanel"
                    aria-labelledby="beneficios-recibos-tab">
                    <transition-group appear name="mis-recibos" id="mis-recibos" tag="div" mode="out-in"
                        v-for="(recibo, index) in recibos" :key="recibo['ruta_recibo']" :index="index">
                        <recibo :key="recibo['ruta_recibo']" :recibo="recibo" :tipo="'PB'" :index="index"
                            :seccion="'gb'" :persona="persona" />
                    </transition-group>
                    <div class="alert alert-warning" v-if="recibos.length === 0">
                        No se encontraron recibos
                    </div>
                </div>
                <div class="tab-pane fade" :id="['beneficios-certificados' + tipo + index]" role="tabpanel"
                    aria-labelledby="beneficios-certificados-tab">
                    <transition-group appear name="mis-recibos" id="mis-certificados-ganancias" tag="div" mode="out-in"
                        v-for="(certificado, index) in certificadosGanacias" :key="certificado['archivo']"
                        :index="index">
                        <certificado :key="certificado['archivo']" :certificado="certificado"
                            :beneficio="data.beneficio_id" :seccion="'gb'" :index="index"></certificado>
                    </transition-group>
                    <div class="alert alert-warning" v-if="certificadosGanacias.length === 0">
                        No se encontraron certificados de ganancias
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Recibo from "../../../mis-documentos/mis-recibos/Recibo";
import Certificado from "../../../mis-documentos/mis-certificados-ganancias/elementos/Certificado";
import { mapState } from "vuex";

export default {
    name: "Beneficio",
    components: {
        Recibo,
        Certificado
    },
    props: [
        'beneficio',
        'tipo',
        'index',
        'recibos',
        'certificadosGanacias'
    ],
    computed: {
        ...mapState({
            persona: state => {
                return state.gestionbeneficiarios.persona
            }
        }),
        data() {
            return this.tipo === 'Apoderado' ? this.beneficio.beneficio : this.beneficio
        }
    },
    methods: {
        generarCertificadoNegativaSalarioFamiliar(beneficio) {
            let data = new FormData()
            data.append('beneficio_id', beneficio)
            axios.post("generar-certificado-negativa", data, {
                responseType: 'blob'
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "pruebas.pdf");
                    document.body.appendChild(link);
                    link.click();
                })
        }
    }
}
</script>

<style scoped></style>
