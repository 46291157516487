import { DateTime } from "luxon";

const formatearFechaYHora = date => {
    return DateTime.fromISO(date)
        .setLocale('es')
        .toFormat('dd LLLL yyyy HH:mm')
}

const formatearFecha = date => {
    return DateTime.fromISO(date)
        .setLocale('es')
        .toFormat('DDD')
}

const formatearStringFechaPublicacion = (date) => {
    const objectDate = new Date(date);
    const day = new Intl.DateTimeFormat("es-ES", { weekday: "long" }).format(objectDate);
    const month = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(objectDate);
    const year = objectDate.getFullYear();
    return `${day.charAt(0).toUpperCase() + day.slice(1)} ${objectDate.getDate()} de ${month.charAt(0).toUpperCase() + month.slice(1)} de ${year}`;
}
const formatearFechaHace = date => {
    return DateTime.fromISO(date).toRelative()
}

const formatearAgregarDias = (date, dias) => {
    return DateTime.fromISO(date)
        .plus({ days: dias })
        .toFormat('DDD')
}

const fechaActual = () => {
    return DateTime.local()
        .toFormat('DDD')
}

const esMailValido = (email) => {
    return email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )!==null
}

const esTelefono = (telefono) => {
    return telefono.toLowerCase().match(/[a-zA-Z]/)==null
}

export {
    esTelefono,
    esMailValido,
    formatearFechaYHora,
    formatearFecha,
    formatearFechaHace,
    formatearAgregarDias,
    fechaActual,
    formatearStringFechaPublicacion
}
